import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CtaSection = props => {
  const renderLink2 = () => {
    if (props.link2 && props.link2text) {
      return (
        <div className="ml-3 inline-flex rounded-md shadow">
          <Link
            to={props.link2}
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            {props.link2text}
          </Link>
        </div>
      )
    }
  }

  return (
    <div className="bg-gray-200">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between gap-8">
        <div>
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 mb-4">
            {props.title1}
          </h2>
          <p className="prose prose-lg text-gray-700 mb-6 lg:max-w-none lg:mb-0">
            {props.text}
          </p>
        </div>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to={props.link1}
              className=" justify-center px-5 py-3 border border-transparent text-base text-center leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {props.link1text}
            </Link>
          </div>
          {renderLink2()}
        </div>
      </div>
    </div>
  )
}

CtaSection.propTypes = {
  title1: PropTypes.string.isRequired,
  text: PropTypes.string,
  link1: PropTypes.string.isRequired,
  link1text: PropTypes.string.isRequired,
  link2: PropTypes.string,
  link2text: PropTypes.string,
}

export default CtaSection
