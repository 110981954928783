import React from "react"
import PropTypes from "prop-types"

const ContTextbox = props => {
  const renderPreHeader = () => {
    if (props.preheader) {
      return (
        <p className="leading-6 text-indigo-500 font-semibold tracking-wide uppercase">
          {props.preheader}
        </p>
      )
    }
  }
  return (
    <div className="mb-8">
      <div className="relative z-10 mb-8 md:mb-2">
        <div className="prose prose-lg  text-base max-w-prose lg:max-w-none">
          {renderPreHeader()}
          <h2
            className={`text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 ${
              props.preheader ? "mt-2" : ""
            }`}
          >
            {props.title}
          </h2>
        </div>
      </div>
      <div className="prose prose-lg text-gray-700 mb-6 lg:max-w-none lg:mb-0">
        {props.children}
      </div>
    </div>
  )
}

ContTextbox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  preheader: PropTypes.string,
}

export default ContTextbox
